import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'virtualMenu.home': 'Home',
  'virtualMenu.back': 'Back',
  'virtualMenu.agenda': 'Agenda',
  'virtualMenu.auditorium': 'Auditorium',
  'virtualMenu.photobooth': 'Photobooth',
  'virtualMenu.chat': 'Chat',
  'virtualMenu.navigation': 'Navigation',
  'virtualMenu.briefcase': 'Briefcase',
  'virtualMenu.speakers': 'Speakers',
  'virtualMenu.helpdesk': 'Help Desk',
  'virtualMenu.mypoints': 'My Points',
  'virtualMenu.meetings': 'Meetings',
  'virtualMenu.leaderboard': 'Leaderboard',
  'virtualMenu.eshop': 'EShop',
  'topMenu.logout': 'Logout',
  'chat.heading': 'Chat',
  'chat.subheading': 'Start New Conversation',
  'chat.attendees': 'Attendees',
  'chat.requests': 'Requests',
  'chat.messages': 'Messages',
  'chat.placeholder': 'Write your message here',
  'streamFeature.heading': 'Interaction Zone',
  'streamFeature.liveChat': 'Live Chat',
  'streamFeature.qna': 'Q&A Panel',
  'streamFeature.polling': 'Live Polling',
  'streamFeature.qnaplaceholder': 'Ask your Question here. Be relevant.',
  'general.resourceCenter': 'Resource Center',
  'search.placeholder': 'Search with Name or Company',
  'helpdesk.introductory': `Welcome to ${process.env.REACT_APP_EVENT_NAME}! We’d love for you to have an amazing experience with us today – how can we help you?`,
  'bottomMenu.groupVideo': 'Join Live Meeting',
  'bottomMenu.enable': 'Enable Bottom Navigation',
  'bottomMenu.chat': 'Chat',
  'bottomMenu.intro': 'Introduction',
  'bottomMenu.meeting': 'Meeting',
  'bottomMenu.dropCard': 'Drop Card',
  'bottomMenu.exhibitors': 'Exhibitors',
  'bottomMenu.socialMedia': 'Social Media',
  'bottomMenu.share': 'Share',
  'bottomMenu.rating': 'Rating',
  'bottomMenu.download': 'Download Dock',
  'streamFeature.raiseHand': 'Raise Hand'
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
