import actions from './actions'

const initialState = {
  scene: {},
  dimensions: {},
  currentMeeting: '',
  meetingVisible: false,
  componentRendered: true,
  isSceneAgendaOpen: false,
  currenSceneAgenda: {},
  agendaLoading: true,
  sessionRender: false,
  currentSession: '',
  currentSessionURL: '',
  isGameStart: false,
  isDocumentListVisible: false,
  currentClickSection: '',
  isQuickViewOpen: false,
  currentFile: {},
  leaderBoardLoading: true,
  isBriefCaseCalled: false,
  isLeaderBoardOpen: false,
  isEShopOpen: false,
  briefcase: {},
  briefcaseLoading: true,
  briefcaseEmailLoading: false,
  navigation: [],
  isDropCardVisible: false,
  hideNumber: false,
  leads: {},
  leadDownloadLoading: false,
  customMessageCard: "Hey! Let's Connect.",
  currentProductDemoUrl: '',
  currentProductDemoHeight: '',
  theHistory: [],
  networkingZoneTopology: {},
  assignedTable: '',
  assignedChair: '',
  isSocialWallOpen: '',
  socialWallURL: '',
  isGamingModelOpen: '',
  gamingData: {},
  dollyStatus: {},
  initialdollyStatus: {},
  anyDollyPlaying: false,
  isSpeakerBioOpen: false,
  currentSpeakerID: '',
  directoryVisible: false,
  subscribedSessionChannels: [],
  onDemandVideoOpen: false,
  onDemandVideoURL: '',
  imagePopOpen: false,
  imagePopURL: '',
  scenePointsClaimed: [],
  sectionPointsClaimed: [],
  thePlaylist: {},
  playlistActive: false,
  currentVideoinPlaylist: null,
  jukebox: [],
  selectedAudio: 0,
  homescreen: '',
  leaderBoardData: {},
  hasWidgetPoints: false,
  widgetPoints: 0,
  widgetID: '',
  isWhereByMeetOpen: false,
  whereByMeetURL: '',
  whereByMeetHostURL: '',
  tableName: '',
  currentWhereByStep: 0,
  isSocialTableInstructionScreenOpen: false,
  isLobbyMusicPlaying: true,
  defaultRedirection: '/main'
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_LEAD:
      return {
        ...state,
        leads: {
          ...state.leads,
          [action.payload.actualload.id]: action.payload.actualload,
        },
      }
    default:
      return state
  }
}
