import store from 'store'
import actions from './actions'

const STORED_VIRTUAL_SETTINGS = storedSettings => {
  const settings = {}
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.virtualSettings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return settings
}

const initialState = {
  ...STORED_VIRTUAL_SETTINGS({
    // Virtual Event Settings
    isVirtualMenuTop: true,
    isVirtualMobileView: false,
    isVirtualMobileMenuOpen: false,
    isVirtualLightTheme: true,
    isVirtualMenuCollapsed: false,
    isSidebarOpen: false,
    virtualLocale: 'en-US',
    isAgendaOpen: false,
    isSpeakersOpen: false,
    isStreamFeatureOpen: false,
    isBriefcaseOpen: false,
    isLeadsOpen: false,
    isProductDemoVisible: false,
    isMeetingWindowVisible: false,
    isMeetingsOpen: false,
    isStreamingEvent: false,
    isDefaultFullScreen: true,
  }),
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
