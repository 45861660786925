import actions from './actions'

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  organization: '',
  mystate: '',
  category: '',
  department: '',
  password: '',
  country: '',
  city: '',
  avatarType: 'text',
  avatarData: '',
  avatarColor: '',
  userID: '',
  loading: false,
  imageURL: '',
  current: 0,
  options: [],
  colors: [ '#ffbf00', '#00a2ae'],
  emailStatus: '',
  phoneStatus: '',
  amount: 0,
  loginEmailStatus:''
}

export default function virtualuserReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
