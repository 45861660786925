import React from 'react'
import { Row, Col, Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Scrollbars } from 'react-custom-scrollbars'
import styles from './style.module.scss'

@withRouter
// @connect(({ user, virtualUser }) => ({ user, virtualUser }))
class LoginLayout extends React.PureComponent {
  render() {
    const { children, location: { pathname }, } = this.props
    console.log('pathname', pathname)
    return (
      <Layout>
        <Layout.Content>
          <div className={styles.container}>
            <div className={styles.left}>
              <Scrollbars style={{ height: '100vh' }}>
                <div className={`${styles.headerlayout} text-center`} style={{ background: pathname.includes('register') ? '#0b0f4f' : 'transparent' }}>
                  <Row>
                    <Col span={3} />
                    <Col span={18} className={`${styles.logos} text-center`}>
                      {/* <img
                        src="resources/images/logo-neo.png"
                        alt="Wiz365 Logo"
                        className={styles.brandlogo}
                      /> */}
                      <img
                        src="resources/images/logo-aws.png"
                        alt="Wiz365 Logo"
                        className={styles.brandlogo}
                      />

                    </Col>
                    <Col span={3} />
                  </Row>
                </div>
                <div className={styles.mainlayout}>
                  {/* <img
                    src="resources/images/logo-inverse-home.png"
                    alt="Wiz365 Logo"
                    className={styles.brandlogo}
                  /> */}
                  <section>{children}</section>
                </div>
                <div className={`${styles.footerlayout} text-center`} style={{ display: pathname.includes('register') ? 'none' : 'block' }}>
                  <Row className={styles.bottomLogos}>
                    <Col span={3} />
                    <Col span={18} className={`${styles.logos} text-center`}>
                      <img
                        src="resources/images/logo-eco.png"
                        alt="Wiz365 Logo"
                        className={styles.brandlogobottom}
                      />
                      {/* <div className={styles.rightBorder} /> */}
                      {/* <img
                        src="resources/images/logo-eco.png"
                        alt="Wiz365 Logo"
                        className={styles.brandlogo}
                      /> */}

                    </Col>
                    <Col span={3} />
                  </Row>
                </div>
              </Scrollbars>
            </div>
            {process.env.REACT_APP_LOGIN_BG === 'video' ? (
              <div
                className={styles.right}
                style={{ backgroundImage: 'url("resources/images/login-bg.jpg")' }}
              >
                <video id="bgvid" playsinline autoPlay muted loop>
                  <source src="resources/videos/login-bg.mp4" type="video/mp4" />
                </video>
              </div>
            ) : (
              <div
                className={styles.right}
                style={{ backgroundImage: isMobile ? 'url("resources/images/login-bg-left.jpg")' : 'url("resources/images/login-bg.jpg")' }}
              >
                <div className={styles.rightlogoCon}>
                  <img
                    src="resources/images/logotorchbearer.png"
                    alt="Wiz365 Logo"
                    className={styles.brandlogoright}
                  />
                </div>
              </div>
            )}
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default LoginLayout
